import { DateTime } from 'luxon'

import { DATE_COMMON_FORMAT } from '@/open-web/utils/constants'

/**
 * Formats a date object to a standardized global date string
 * @param date The date to format
 * @param locale Optional locale string (defaults to 'en')
 * @param timeZone Optional timezone (defaults to system timezone)
 * @returns Formatted date string
 */
export const formatGlobalDate = (
  date: Date,
  locale: string = 'en',
  timeZone?: string,
  format?: string,
): string => {
  const dateTime = DateTime.fromJSDate(date)
  const simplifiedLocale = locale.split('-')[0]
  const formattedDate = dateTime
    .setZone(timeZone)
    .setLocale(simplifiedLocale)
    .toFormat(format ?? DATE_COMMON_FORMAT)
  return formattedDate
}

/**
 * Formats a Date object or date string to a standardized date string
 */
export function formatDate(date: Date | string, locale?: string, timeZone?: string): string
export function formatDate(date: null | undefined, locale?: string, timeZone?: string): null
export function formatDate(
  date: Date | string | null | undefined,
  locale?: string,
  timeZone?: string,
): string | null
// eslint-disable-next-line func-style
export function formatDate(
  date: Date | string | null | undefined,
  locale?: string,
  timeZone?: string,
): string | null {
  if (!date) {
    return null
  }
  try {
    const dateObj = typeof date === 'string' ? new Date(date) : date
    return formatGlobalDate(dateObj, locale, timeZone)
  } catch {
    return null
  }
}
