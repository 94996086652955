/* eslint-disable react-hooks/exhaustive-deps */

'use client'

import { useEffect, useRef, useState } from 'react'
import { match } from 'ts-pattern'
import { z } from 'zod'

import { usePathname } from '@/i18n/navigation'
import { browserEnvs } from '@/shared/envs'
import type { CustomerType } from '@/shared/graphql/schema/commonBackend/graphql'
import { useGlobalStore } from '@/shared/store/provider'
import { logError } from '@/shared/utils/error'
import { UiError } from '@/shared/utils/errorClasses'
import { getLocalStorageValue, setLocalStorageValue } from '@/shared/utils/localStorage'
import { sendGTMEvent } from '@/shared/utils/sendGTMEvent'
import { getSessionStorageValue, setSessionStorageValue } from '@/shared/utils/sessionStorage'

import { getUserSessionForGA } from './getUserSessionForGA'

type UserStatusProps = {
  userStatus?: UserStatusSession
}

export type UserStatusSession = {
  customerType: CustomerType | undefined
  sha256CustomerId: string
  customerIdNumber: string
  loggedIn: boolean
  isCustomer: boolean
}

export type WindowWithLoggedInData = Window & {
  loggedInData: { customerId?: string }
}

declare const windowWithLoggedInData: WindowWithLoggedInData

declare const window: WindowWithLoggedInData

export const UserStatus = ({ userStatus }: UserStatusProps) => {
  const [status, setStatus] = useState<UserStatusSession | null>()
  const initial = useRef(false)

  useEffect(() => {
    if (!initial.current) {
      initial.current = true

      if (!userStatus) {
        getUserSessionForGA()
          .then((data) => {
            setStatus(data)
          })
          .catch((error) => {
            logError(new UiError(error))
          })
      } else {
        setStatus(userStatus)
      }
    }
  }, [])
  return initial.current && <UpdateUserStatus status={status} />
}

export const UpdateUserStatus = ({ status }: { status: UserStatusSession | null | undefined }) => {
  const pathname = usePathname()
  const [eventSentPage, setEventSentPage] = useState<string>(pathname)
  const customer = useGlobalStore((state) => state.customer)
  const previousShaCustomerIdValue = getSessionStorageValue('customerId', z.string(), '')
  const previousCustomerId = getSessionStorageValue('customerIdNumber', z.string(), '')
  const sha256NewCustomerId =
    !status?.sha256CustomerId || status?.sha256CustomerId === 'undefined'
      ? previousShaCustomerIdValue
      : status?.sha256CustomerId
  const newCustomerId =
    !status?.customerIdNumber || status?.customerIdNumber === 'undefined'
      ? previousCustomerId
      : status?.customerIdNumber // This is used by Forsta customer feedback tool
  //TODO: Fix it, what is customer type from the status and why we need it how does it works?
  const {
    private: { contractTemplate },
  } = useGlobalStore((store) => store.selectedContract)
  const { setCustomerType } = useGlobalStore((store) => store.gtm)
  const customerType = getLocalStorageValue('customerType', z.string(), '')
  const templateCustomerType = contractTemplate?.customerType
  const initialized = useRef(false)
  const sendEvent = () =>
    sendGTMEvent({
      event: 'user_status_update',
      is_customer:
        (sha256NewCustomerId && sha256NewCustomerId !== 'undefined') ||
        customer._isExistingCustomer ||
        status?.isCustomer
          ? 'true'
          : 'false',
      logged_in: status?.loggedIn ? 'true' : 'false',
      customer_type: customerType,
      site_brand: `fortum ${browserEnvs.NEXT_PUBLIC_COUNTRY}`,
      customer_id: sha256NewCustomerId,
    })

  const updateCustomerIdForForsta = () => {
    if (window !== undefined) {
      window.loggedInData = window.loggedInData || {}
      window.loggedInData.customerId = newCustomerId // This is used by Forsta customer feedback tool
    }
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      sendEvent()
      updateCustomerIdForForsta()
    }
  }, [])

  useEffect(() => {
    if (eventSentPage !== pathname) {
      sendEvent()
      updateCustomerIdForForsta()
      setEventSentPage(pathname)
    }
  }, [pathname])

  const handleCustomerTypeChange = (customerType: CustomerType) => {
    const type = customerTypeToGTM(customerType)

    setLocalStorageValue('customerType', type)
    setCustomerType(type)
  }
  useEffect(() => {
    if (templateCustomerType) {
      handleCustomerTypeChange(templateCustomerType)
    } else if (status?.customerType) {
      handleCustomerTypeChange(status.customerType)
    }
    setSessionStorageValue('customerId', sha256NewCustomerId)
  }, [templateCustomerType, status])

  return null
}

export const customerTypeToGTM = (customerType: string) =>
  match(customerType.toUpperCase())
    .with('ENTERPRISE', () => 'B2B')
    .otherwise(() => 'B2C')
