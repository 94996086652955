import { useTranslations } from 'next-intl'
import styled from 'styled-components'

import type { ContentTextProps, FontSize } from '@fortum/elemental-ui'
import { Box, ContentText, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'

export type PriceItemProps = {
  /**
   * Top label of the price section
   */
  priceLabel?: string | null
  /**
   * Actual price text
   */
  price: string
  /**
   * Pirce without discounts
   */
  originalPrice?: string
  /**
   * Description of the original price eg.: Sale
   */
  originalPriceDescription?: string
  /**
   * Disclaimer text for VAT
   */
  vatDisclaimer?: string
  /**
   * Definition if the price info is horizontally or vertically spaced
   */
  flexDirection?: 'row' | 'column'
  /**
   * text content alignment
   */
  alignContent?: 'left' | 'center' | 'right'
  /**
   * Font size of the main price
   */
  headingSize?: FontSize
  /**
   * Font size of the original price
   */
  priceStrikeThroughSize?: FontSize
  /**
   * Set custom color
   */
  colors?: ColorProps<never, 'textPrimary' | 'textPositive' | 'textSecondary'>
  /**
   * Set text transfrom for description
   */
  descriptionTextTransform?: ContentTextProps['textTransform']
}

/**
 * Present prices of the contract up to 4 lines
 */
export const PriceItem = ({
  priceLabel,
  price,
  originalPrice,
  originalPriceDescription,
  vatDisclaimer,
  flexDirection = 'column',
  alignContent = 'left',
  headingSize = 'xl',
  priceStrikeThroughSize = 'xs',
  colors,
  descriptionTextTransform = 'none',
}: PriceItemProps) => {
  const theme = useTheme()
  const defaultColors = theme.colors
  const t = useTranslations('ariaLabels')
  const textColor = {
    textPrimary: colors?.textPrimary ?? defaultColors.textPrimary,
    textSecondary: colors?.textSecondary ?? defaultColors.textSecondary,
    textPositive: colors?.textPositive ?? defaultColors.textPositive,
  }

  return (
    <Box display="flex" flexDirection={flexDirection} data-testid="price-item">
      {priceLabel && (
        <ContentText
          size="s"
          textColor={textColor.textPrimary}
          mr={flexDirection === 'row' ? spacing.xxs : 0}
          data-testid="price-label"
        >
          {priceLabel}
        </ContentText>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems={getAlignContent(alignContent)}
        data-testid="price-item-prices"
      >
        <ContentText
          textColor={textColor.textPrimary}
          size={headingSize}
          fontWeight={fontWeights.medium}
          textAlign={alignContent}
          aria-label={originalPrice ? t('discountPrice') : t('price')}
          data-testid="price-value"
        >
          {price}
        </ContentText>
        {originalPrice && (
          <PriceStrikethrough
            color={textColor.textSecondary}
            size={priceStrikeThroughSize}
            aria-label={t('originalPrice')}
            data-testid="original-price-value"
          >
            {originalPrice}
          </PriceStrikethrough>
        )}
        {originalPriceDescription && (
          <ContentText
            fontSize={fontSizes.xs}
            color={textColor?.textPositive}
            textWrap="word"
            data-testid="original-price-description"
            textTransform={descriptionTextTransform}
          >
            {originalPriceDescription}
          </ContentText>
        )}
        {vatDisclaimer && (
          <ContentText
            fontSize={fontSizes.xs}
            color={colors?.textSecondary}
            data-testid="vat-disclaimer"
          >
            {vatDisclaimer}
          </ContentText>
        )}
      </Box>
    </Box>
  )
}

const getAlignContent = (alignContent: PriceItemProps['alignContent']) => {
  switch (alignContent) {
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
    default:
      return 'flex-start'
  }
}

const PriceStrikethrough = styled(ContentText)`
  text-decoration: line-through;
`
