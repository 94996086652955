import { useTimeZone } from 'next-intl'

import { useFullLocale } from '@/shared/locale'

import { formatGlobalDate } from '../utils/formatters/dateCommonFormatter'

export const useCommonDateFormatter = () => {
  const locale = useFullLocale()
  const timeZone = useTimeZone()

  function formatDate(date: Date | string, format?: string): string
  function formatDate(date: null | undefined, format?: string): null
  function formatDate(date: Date | string | null | undefined, format?: string): string | null
  // eslint-disable-next-line func-style
  function formatDate(date: Date | string | null | undefined, format?: string): string | null {
    if (!date) {
      return null
    }

    const dateObj = typeof date === 'string' ? new Date(date) : date
    return formatGlobalDate(dateObj, locale, timeZone, format)
  }

  return { formatDate }
}
