import type { TariffElement } from '../graphql/schema/commonBackend/graphql'
import type { EnrichedContractTemplate } from '../services/campaignDataResolver'

type TwoTimeMeasurementPrices = {
  nightPrices: TariffElement | undefined
  dayPrices: TariffElement | undefined
  winterPrices: TariffElement | undefined
  otherSeasonPrices: TariffElement | undefined
  monthlyFees: TariffElement | undefined
}

export const TARIFF_ELEMENT_TYPE_PREFIX = {
  energy: 'energy',
  monthlyFee: 'monthlyFee',
  discount: 'discount',
  discountEnergy: 'discount.energy',
  discountMonthlyFee: 'discount.monthlyFee',
}

export const SEASON_ENERGY_PRICE_ELEMENTS = [
  'energy.day',
  'energy.night',
  'energy.winterDay',
  'energy.otherSeason',
]

export const isEnergyElement = (type: string) => type.startsWith(TARIFF_ELEMENT_TYPE_PREFIX.energy)
export const isEnergyDiscountElement = (type: string) =>
  type.startsWith(TARIFF_ELEMENT_TYPE_PREFIX.discountEnergy)
export const isMonthlyFeeElement = (type: string) =>
  type.startsWith(TARIFF_ELEMENT_TYPE_PREFIX.monthlyFee)
export const isMonthlyFeeDiscountElement = (type: string) =>
  type.startsWith(TARIFF_ELEMENT_TYPE_PREFIX.discountMonthlyFee)
export const isDiscountForElement = (type: string, discountType: string) =>
  type === discountType.replace(`${TARIFF_ELEMENT_TYPE_PREFIX.discount}.`, '')

export const getTwoTimeMeasurementPrices = (
  contractTemplate: EnrichedContractTemplate,
): TwoTimeMeasurementPrices => {
  const nightPrices = contractTemplate.tariffElements.find(
    (element) => element.type === 'energy.night',
  )
  const dayPrices = contractTemplate.tariffElements.find((element) => element.type === 'energy.day')
  const winterPrices = contractTemplate.tariffElements.find(
    (element) => element.type === 'energy.winterDay',
  )
  const otherSeasonPrices = contractTemplate.tariffElements.find(
    (element) => element.type === 'energy.otherSeason',
  )
  const monthlyFees = contractTemplate.tariffElements.find(
    (element) => element.type === 'monthlyFee',
  )

  return {
    nightPrices,
    dayPrices,
    winterPrices,
    otherSeasonPrices,
    monthlyFees,
  }
}
