import type { NumberFormatOptions } from 'next-intl'
import { useTranslations } from 'next-intl'

import type { TranslateUnit } from '@/open-web/utils/translateUnits'
import { translatePriceUnit } from '@/open-web/utils/translateUnits'
import { useFormatter } from '@/shared/locale'

export const defaultOptions = { maximumFractionDigits: 2, minimumFractionDigits: 2 }

/**
 * Hook that returns price formatters. Should be used across entire app.
 * TODO: Stop using string as unit type whenever all the GAPI data start supporting DetailedUnit = amountUnit, priceUnit, currency
 */
export const usePriceToString = () => {
  const { number } = useFormatter()
  const t = useTranslations('units')

  const translateUnit = (unit: TranslateUnit) => {
    const translationKeys = translatePriceUnit(unit)

    if (translationKeys) {
      return `${t(translationKeys.priceUnitKey)}/${t(translationKeys.amountUnitKey)}`
    }

    return typeof unit === 'string' ? unit : 'unknown'
  }

  const priceToString = (
    price: Price,
    translatedUnit: string | undefined,
    options?: NumberFormatOptions,
  ) => {
    const unit = translatedUnit ? ` ${translatedUnit}` : ''
    return typeof price == 'number' && !Number.isNaN(price)
      ? `${number(price, options ?? defaultOptions)}${unit}`
      : '--'
  }

  return {
    buildFormatter: <T>(
      unit: TranslateUnit,
      mapper?: (arg: T) => number | undefined,
    ): PriceToStringFormatterBuilder<T> => {
      const translatedUnit = translateUnit(unit)
      const formatter: PriceToStringFormatterBuilder<T> = (
        price: T,
        options?: NumberFormatOptions,
      ) => {
        if (mapper) {
          return priceToString(mapper(price), translatedUnit, options)
        }

        return priceToString(Number(price), translatedUnit, options)
      }

      return formatter
    },
    formatPriceWithUnit: (
      price: Price,
      unit: TranslateUnit,
      options?: NumberFormatOptions,
    ): string => {
      const translatedUnit = translateUnit(unit)
      return priceToString(price, translatedUnit, options)
    },
    formatUnit: (unit: TranslateUnit): string => {
      return translateUnit(unit)
    },
    formatPrice: (price: Price, options?: NumberFormatOptions): string => {
      return priceToString(price, undefined, options)
    },
  }
}

type Price = number | undefined | null
export type PriceToStringFormatter = (price: Price, options?: NumberFormatOptions) => string
export type PriceToStringFormatterBuilder<T> = (price: T, options?: NumberFormatOptions) => string

export type PriceToStringWithUnitFormatter = (
  price: Price,
  unit: TranslateUnit,
  options?: NumberFormatOptions,
) => string
