import type { AmountUnit, Currency, PriceUnit } from '@/shared/graphql/schema/commonBackend/graphql'
import { logError } from '@/shared/utils/error'
import { TranslationError } from '@/shared/utils/errorClasses'

export type DetailedUnit = {
  amountUnit: AmountUnit
  priceUnit: PriceUnit
  currency: Currency
}

export type TranslatedPriceUnit = {
  priceUnitKey: PriceUnitKey
  amountUnitKey: AmountUnitKey
}

export type TranslateUnit = string | DetailedUnit | null | undefined

type PriceUnitKey =
  | 'currency.majorUnit.EUR'
  | 'currency.majorUnit.SEK'
  | 'currency.majorUnit.NOK'
  | 'currency.minorUnit.EUR'
  | 'currency.minorUnit.SEK'
  | 'currency.minorUnit.NOK'
type AmountUnitKey = 'day' | 'month' | 'year' | 'piece' | 'kilowattsPerHour'

export const translatePriceUnit = (unit: TranslateUnit): TranslatedPriceUnit | undefined => {
  try {
    const prefixMajorUnit = `currency.majorUnit`
    const prefixMinorUnit = `currency.minorUnit`

    // NOTE: That should be use only by open web and campaign data, logged in team should not use pricingUnit since it is deprecated field.
    if (typeof unit === 'string') {
      switch (unit) {
        case 'kr/mån':
          return { priceUnitKey: `${prefixMajorUnit}.SEK`, amountUnitKey: 'month' }
        case '€/mnth':
          return { priceUnitKey: `${prefixMajorUnit}.EUR`, amountUnitKey: 'month' }
        case '€/pcs':
          return { priceUnitKey: `${prefixMajorUnit}.EUR`, amountUnitKey: 'piece' }
        case 'kr/mnd':
          return { priceUnitKey: `${prefixMajorUnit}.NOK`, amountUnitKey: 'month' }
        case 'öre/kWh':
          return { priceUnitKey: `${prefixMinorUnit}.SEK`, amountUnitKey: 'kilowattsPerHour' }
        case 'c/kWh':
          return { priceUnitKey: `${prefixMinorUnit}.EUR`, amountUnitKey: 'kilowattsPerHour' }
        case 'øre/kWh':
          return { priceUnitKey: `${prefixMinorUnit}.NOK`, amountUnitKey: 'kilowattsPerHour' }
        default:
          throw new TranslationError('Cannot translate string unit', { cause: unit })
      }
    }

    if (unit && typeof unit === 'object') {
      const { amountUnit, currency, priceUnit } = unit

      const priceUnitKey = mapCurrency(priceUnit, currency)
      const amountUnitKey = translateAmountUnit(amountUnit)

      return { priceUnitKey, amountUnitKey }
    }
  } catch (error) {
    logError(new TranslationError(error, { cause: unit }))
  }

  return undefined
}

export const translateAmountUnit = (amountUnit: AmountUnit): AmountUnitKey => {
  switch (amountUnit) {
    case 'DAY':
      return `day`
    case 'KWH':
      return `kilowattsPerHour`
    case 'MONTH':
      return `month`
    case 'PIECE':
      return `piece`
    case 'YEAR':
      return `year`
    case 'UNKNOWN':
      throw new TranslationError('Cannot find amount unit', {
        cause: amountUnit,
      })
  }
}

export const mapCurrency = (priceUnit: PriceUnit, currency: Currency): PriceUnitKey => {
  const currencyPrefix = priceUnit === 'MAJOR_UNIT' ? 'currency.majorUnit' : 'currency.minorUnit'
  switch (currency) {
    case 'EUR':
      return `${currencyPrefix}.EUR`
    case 'SEK':
      return `${currencyPrefix}.SEK`
    case 'NOK':
      return `${currencyPrefix}.NOK`
    default:
      throw new TranslationError('Unknown currency', {
        cause: { priceUnit, currency },
      })
  }
}
