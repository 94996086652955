import { DateTime } from 'luxon'

import { DATE_FORMAT_FOR_API } from '@/open-web/utils/constants'
import { browserEnvs } from '@/shared/envs'
import type { PriceType } from '@/shared/graphql/schema/commonBackend/graphql'
import { logError } from '@/shared/utils/error'
import { UiError } from '@/shared/utils/errorClasses'

const PRICE_TYPE_START_DATE_OFFSET: Record<
  Extract<PriceType, 'FIXED' | 'HYBRID' | 'SPOT' | 'FLAT_RATE'>,
  number
> = {
  FIXED: 16,
  HYBRID: 16,
  SPOT: 5,
  //TODO: Check that value
  FLAT_RATE: 16,
} as const

//TODO: Apply logic base on the overwrites from campaign configuration
export const getDefaultStartDateByPriceType = (priceType: PriceType) => {
  if (
    priceType !== 'FIXED' &&
    priceType !== 'SPOT' &&
    priceType !== 'HYBRID' &&
    //TODO: Make this method country specific
    priceType !== 'FLAT_RATE'
  ) {
    throw logError(new UiError(`Unknown priceType: ${priceType}`))
  }

  if (browserEnvs.NEXT_PUBLIC_COUNTRY === 'FI') {
    return DateTime.now().toFormat(DATE_FORMAT_FOR_API)
  }

  const offset = PRICE_TYPE_START_DATE_OFFSET[priceType]

  return DateTime.now().plus({ days: offset }).toFormat(DATE_FORMAT_FOR_API)
}
